<template>
  <KDialog
    :title="`Ver Cobrança #${form.id}`"
    :visible="visible"
    :loading="loading"
    @click:close="close"
  >
    <KForm>
      <v-row class="my-0">
        <!-- Receita/Despesa -->
        <v-col cols="6" sm="4">
          <KLabel label="Receita/Despesa">{{
            form.multiplier === 1 ? "Receita" : "Despesa"
          }}</KLabel>
        </v-col>

        <!-- Categoria -->
        <v-col cols="6" sm="8">
          <KLabel label="Categoria">
            {{ form.category && form.category.name }}
          </KLabel>
        </v-col>

        <!-- Data de Vencimento -->
        <v-col cols="6" sm="4">
          <KLabel label="Vencimento">
            {{ form.expires_at | dateBR }}
          </KLabel>
        </v-col>

        <!-- Status -->
        <v-col cols="6" sm="4">
          <KLabel label="Status">
            {{ form.status && form.status.name }}
          </KLabel>
        </v-col>

        <!-- Conexão do gateway -->
        <v-col cols="6" sm="4">
          <KLabel label="Conta" truncate>
            {{ form.connection && form.connection.name }}
          </KLabel>
        </v-col>

        <!-- cliente -->
        <v-col cols="12">
          <KLabel label="Cliente">{{
            form.customer && form.customer.name
          }}</KLabel>
        </v-col>

        <!-- Descrição -->
        <v-col cols="12">
          <KLabel label="Descrição" v-bind="inputSettings">{{
            form.description
          }}</KLabel>
        </v-col>

        <!-- ID Público -->
        <v-col cols="6" sm="4">
          <KLabel label="ID Público">
            {{ form.public_id }}
          </KLabel>
        </v-col>

        <!-- Valor -->
        <v-col cols="6" sm="4">
          <KLabel label="Valor">
            {{ form.amount | currency }}
          </KLabel>
        </v-col>

        <!-- Módulo -->
        <v-col cols="6" sm="4" v-if="moduleName">
          <KLabel label="Módulo">
            {{ moduleName && moduleName.text }} #{{ form.module_id }}
          </KLabel>
        </v-col>
      </v-row>

      <v-row class="my-0">
        <v-col cols="12">
          <SectionHeader title="Ações" />
        </v-col>

        <v-col cols="12">
          <v-card outlined rounded="lg">
            <ExplicatedAction
              v-once
              title="Ver boleto"
              color="primary"
              event-name="click:billet"
              @click:billet="onClickBillet"
              description="Abrir o boleto web"
            />

            <v-divider></v-divider>

            <ExplicatedAction
              v-once
              title="Atualização do Status"
              color="primary"
              event-name="click:force-update"
              @click:force-update="onClickForceUpdate"
              description="O sistema irá se conectar ao gateway de pagamento, verificando o status 
              deste boleto e atualizando as informações do sistema de acordo com as recebidas."
            />

            <v-divider></v-divider>

            <ExplicatedAction
              v-once
              title="Gerar 2ª via"
              color="primary"
              event-name="click:duplicate"
              @click:duplicate="onClickDuplicate"
              description="Esta cobrança será cancelada e outra cobrança com as mesmas 
              informações será criada. Uma nova tela abrirá para incluir multa, juros e uma nova 
              data de vencimento."
            />

            <v-divider></v-divider>

            <ExplicatedAction
              v-once
              title="Cancelar"
              color="red"
              event-name="click:cancel"
              @click:cancel="onClickCancel"
              description="Ao cancelar uma cobrança no sistema, ela também será 
              cancelada no banco emissor. Evite cancelar sem o conhecimento 
              do cliente, evitando tentativas de pagamento desnecessárias."
            />

            <v-divider></v-divider>

            <ExplicatedAction
              v-once
              title="Desassociar"
              color="red"
              event-name="click:disassociate"
              @click:disassociate="onClickDisassociate"
              description="Ao desassociar uma cobrança, ela ainda continuará no
                    sistema, porém não mais associada a um módulo."
            />
          </v-card>
        </v-col>
      </v-row>
    </KForm>

    <!--  -->
    <DialogChargeDuplicate
      ref="dialog_charge_duplicate"
      @save="$emit('save')"
    />
  </KDialog>
</template>

<script>
import KForm from "@/components/KForm";
import KLabel from "@/components/KLabel";
import KDialog from "@/components/KDialog";
import KInputMoney from "@/components/KInput/Money";
import KInputMasked from "@/components/KInput/Masked";
import SectionHeader from "@/components/SectionHeader";

import ExplicatedAction from "@/components/ExplicatedAction";
import DialogChargeDuplicate from "./DialogChargeDuplicate";

export default {
  components: {
    KForm,
    KLabel,
    KDialog,
    KInputMoney,
    KInputMasked,
    SectionHeader,
    ExplicatedAction,
    DialogChargeDuplicate,
  },

  data() {
    return {
      visible: false,
      loading: false,
      form: {},
      inputSettings: {
        dense: true,
        outlined: true,
        disabled: true,
      },
    };
  },

  computed: {
    moduleItems() {
      return this.$store.getters["financials/modules"];
    },
    moduleName() {
      return this.moduleItems.find(module => module.value === this.form.module);
    },
  },

  methods: {
    open(item) {
      this.form = item;
      this.visible = true;
    },

    async onClickBillet() {
      try {
        console.log(
          `${this.$api.url(
            `/financials/charges/${this.form.public_id}/billet_web`
          )}`
        );
        window.open(
          `${this.$api.url(
            `/financials/charges/${this.form.public_id}/billet_web`
          )}`
        );
      } catch (error) {
        this.$message.serverError(error);
      }
    },

    async onClickDisassociate() {
      try {
        if (confirm("Deseja realmente desassociar esta cobrança?")) {
          this.loading = true;
          await this.$store.dispatch("financials/disassociate", this.form.id);

          this.loading = false;
          this.$message.success("Cobrança desassociada");
        } else {
          this.$message.show("Operação não executada");
        }
      } catch (error) {
        this.loading = false;
        this.$message.serverError(error);
      }
    },

    async onClickForceUpdate() {
      console.log("entrou no force");
      try {
        this.loading = true;
        await this.$store.dispatch("financials/forceUpdate", this.form.id);

        this.loading = false;
        this.$message.success("Status atualizado");
      } catch (error) {
        this.loading = false;
        this.$message.serverError(error);
      }
    },

    /**
     * Gerar segunda via
     */
    async onClickDuplicate() {
      console.log("entrou em segunda via");
      try {
        this.$refs["dialog_charge_duplicate"].open(this.form);
      } catch (error) {}
    },

    async onClickCancel() {
      try {
        if (
          confirm(
            "Tem certeza que deseja cancelar essa cobrança? Esta operação não pode ser revertida."
          )
        ) {
          this.loading = true;
          await this.$store.dispatch("financials/cancel", this.form.id);

          this.loading = false;
          this.$message.success("Cobrança cancelada");
        } else {
          this.$message.show("Operação não executada");
        }
      } catch (error) {
        this.loading = false;
        this.$message.serverError(error);
      }
    },

    close() {
      this.visible = false;
    },
  },
};
</script>

<style>
</style>
