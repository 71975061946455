//
// FRONTEND
//
import { currencyBR } from "klawtil";
import moment from "moment";

//
// BACKEND
//
// const { currencyBR } = require("klawtil");
// const moment = require("moment");

function feesCalculate(payload, charge, connection) {
  try {
    const fees = {
      fine: 0,
      interest: 0,
    };

    if (!payload.apply_fees) return fees;

    // Se o boleto atual ainda não estiver vencido, retorne o valor puro
    if (
      moment(charge.expires_at)
        .startOf("day")
        .isSameOrAfter(moment().startOf("day"))
    ) {
      return fees;
    }

    // Se data inicial de juros for menor que a data final,
    // não calcule nada
    if (payload.fees_starts_at > payload.fees_ends_at)
      throw {
        code: 403,
        message: "Data inicial precisa ser menor que a final",
      };

    if (!connection) return {};

    // Dados de multa e juros na conexão
    const { fine, interest } = connection.settings;

    // Número de dias desde a expiração
    const expiredDays = moment(moment(payload.fees_ends_at)).diff(
      payload.fees_starts_at,
      "days"
    );

    let amount = +charge.amount;

    // Multa - Porcentagem
    if (fine.type === "percent")
      fees.fine = (+charge.amount * +fine.value) / 100;

    // Multa - Valor fixo
    if (fine.type === "value") fees.fine = +fine.value;

    // Juros - Porcentagem
    if (interest.type === "percent")
      fees.interest =
        +((+charge.amount * +interest.value) / 100).toFixed(2) * expiredDays;

    // Juros - Valor fixo
    if (interest.type === "value")
      fees.interest = +interest.value * expiredDays;

    fees.fine = +fees.fine.toFixed(2);
    fees.interest = +fees.interest.toFixed(2);
    fees.amount = amount;
    fees.final_amount = amount + fees.fine + fees.interest;
    fees.expired_days = expiredDays;

    // Formatado
    fees.fine_formatted = currencyBR(fees.fine);
    fees.interest_formatted = currencyBR(fees.interest);
    fees.amount_formatted = currencyBR(amount);
    fees.final_amount_formatted = currencyBR(fees.final_amount);

    return fees;
  } catch (error) {
    console.log("Erro no feesCalculate", error);

    //
    // FRONTEND
    //
    // Frontent deve retornar um objeto vazio em caso de erro
    return {};

    //
    // BACKEND
    //
    // throw error;
  }
}

//
// BACKEND
//
// module.exports = feesCalculate;

//
// FRONTEND
//
export default feesCalculate;
