<template>
  <KPage>
    <KAlert info>
      Nenhum devedor encontrado
    </KAlert>

    <!-- Lista de devedores -->
    <DebtorItem v-for="debtor in debtors" :key="debtor.id" v-bind="debtor" @click:item="onItemClick" />

    <!-- Botão de exportar -->
    <v-row v-if="debtors.length">
      <v-col cols="12" class="d-flex align-center justify-center">
        <v-btn rounded depressed @click="onExportClick">
          <v-icon left>mdi-microsoft-excel</v-icon>
          Exportar
        </v-btn>
      </v-col>
    </v-row>

    <!-- Dialogs -->
    <DialogViewCharge ref="dialog_view_charge" />
    <DialogSave ref="dialog_save" @save="load" />
  </KPage>
</template>

<script>
import KPage from "@/components/KPage";
import KAlert from "@/components/KAlert";
import SectionHeader from "@/components/SectionHeader";

import DebtorItem from "./DebtorItem";

import DialogViewCharge from "../_shared/DialogViewCharge";
import DialogSave from "../_shared/DialogSave";

export default {
  components: {
    KPage,
    KAlert,
    SectionHeader,

    DebtorItem,

    DialogViewCharge,
    DialogSave
  },

  data() {
    return {
      debtors: []
    };
  },

  methods: {
    /**
     *
     */
    async onItemClick(payload) {
      try {
        this.$loading.show();
        const { data } = await this.$api.get("/financials/" + payload.id);

        const item = data.data;

        if (item.billet_text_line) {
          this.$refs["dialog_view_charge"].open(item);
        } else {
          this.$refs["dialog_save"].open({ ...item });
        }
        this.$loading.hide();
      } catch (error) {
        this.$message.serverError(error);
        this.$loading.hide();
      }
    },

    /**
     *
     */
    async onExportClick() {
      try {
        this.$loading.show();
        await this.$api.download(
          "/financials/reports/debtors",
          { download: true },
          "Relatorio.xlsx"
        );
        this.$loading.hide();
      } catch (error) {
        this.$message.serverError(error);
        this.$loading.hide();
      }
    },

    /**
     *
     */
    async load() {
      try {
        this.$loading.show();
        const { data } = await this.$api.get("/financials/reports/debtors");

        this.debtors = data.data;

        this.$loading.hide();
      } catch (error) {
        this.$loading.hide();
        this.$message.serverError(error);
      }
    }
  },

  mounted() {
    this.load();
  }
};
</script>

<style></style>
