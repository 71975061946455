<template>
  <KDialog
    :title="`Gerar 2ª Via`"
    :visible="visible"
    :loading="loading"
    :actions="dialogActions"
    @click:save="save"
    @click:close="close"
  >
    <KForm ref="form">
      <v-row class="my-0">
        <v-col cols="12">
          <SectionHeader title="Dados da Segunda Via" />
        </v-col>

        <!-- Nova data de vencimento -->
        <v-col cols="12" sm="6" class="py-1">
          <KInputDate
            label="Nova data de vencimento"
            v-model="form.expires_at"
            :error-messages="errors.expires_at"
            v-bind="inputSettings"
          />
        </v-col>

        <v-col cols="12" sm="6" class="py-1">
          <v-select
            label="Conexão"
            item-text="name"
            item-value="id"
            return-object
            :items="connections"
            :error-messages="errors.connection_id"
            v-model="form.connection"
            v-bind="inputSettings"
          />
        </v-col>

        <!-- Aplicar Juros à Segunda via? -->
        <v-col cols="12" sm="6" class="py-1">
          <v-switch
            class="ma-0"
            inset
            label="Aplicar multa e juros?"
            v-model="form.apply_fees"
            :error-messages="errors.apply_fees"
          />
        </v-col>
      </v-row>

      <!-- Mensagem -->
      <v-expand-transition>
        <v-row v-show="form.apply_fees && !form.connection" class="ma-0">
          <v-col cols="12">
            <KAlert warning class="ma-0">
              Selecione a conexão para o cálculo correto dos valores
            </KAlert>
          </v-col>
        </v-row>
      </v-expand-transition>

      <v-expand-transition>
        <v-row v-show="form.apply_fees" class="ma-0">
          <!-- Mensagem exibida quando o boleto ainda não venceu mas o 
          usuário selecionou a opção de incluir juros -->
          <v-col v-if="!isExpired" cols="12">
            <KAlert error class="ma-0"> Boleto ainda não vencido. </KAlert>
          </v-col>

          <v-col cols="12">
            <SectionHeader title="Dados da Conexão" />
          </v-col>

          <v-col cols="12" sm="6" class="py-1">
            <KLabel label="Multa da Conexão por Atraso">{{ fine }}</KLabel>
          </v-col>

          <v-col cols="12" sm="6" class="py-1">
            <KLabel label="Juros da Conexão ao Dia">{{ interest }}</KLabel>
          </v-col>

          <v-col cols="12">
            <SectionHeader title="Período de Aplicação de Juros" />
          </v-col>

          <!--  -->
          <v-col cols="12">
            <KAlert info>
              <div>
                <p class="ma-0">Data Inicial: Dia seguinte ao vencimento.</p>
                <p class="ma-0">Data final: Data atual</p>
              </div>
            </KAlert>
          </v-col>

          <!-- Data inicial -->
          <v-col cols="12" sm="6" class="py-1">
            <KInputDate
              label="Data inicial"
              v-model="form.fees_starts_at"
              :error-messages="errors.fees_starts_at"
              v-bind="inputSettings"
            />
          </v-col>

          <!-- Data final -->
          <v-col cols="12" sm="6" class="py-1">
            <KInputDate
              label="Data final"
              v-model="form.fees_ends_at"
              :error-messages="errors.fees_ends_at"
              v-bind="inputSettings"
            />
          </v-col>

          <v-col cols="12">
            <SectionHeader title="Resultado" />
          </v-col>

          <!--  -->
          <v-col cols="6" sm="4" class="py-1">
            <KLabel label="Valor Inicial">{{
              auxliaryData.amount_formatted
            }}</KLabel>
          </v-col>

          <v-col cols="6" sm="4" class="py-1">
            <KLabel label="Dias de Juros">{{
              auxliaryData.expired_days
            }}</KLabel>
          </v-col>

          <v-col cols="6" sm="4" class="py-1">
            <KLabel label="Multa Calculada">{{
              auxliaryData.fine_formatted
            }}</KLabel>
          </v-col>

          <v-col cols="6" sm="4" class="py-1">
            <KLabel label="Juros Calculados">{{
              auxliaryData.interest_formatted
            }}</KLabel>
          </v-col>

          <v-col cols="6" sm="4" class="py-1">
            <KLabel label="Valor Final">{{
              auxliaryData.final_amount_formatted
            }}</KLabel>
          </v-col>
        </v-row>
      </v-expand-transition>
    </KForm>

    <!--  -->
    <!-- {{ payload }} -->
    <!-- <br /> -->
    <!-- {{ auxliaryData }} -->
  </KDialog>
</template>

<script>
import KForm from "@/components/KForm";
import KAlert from "@/components/KAlert";
import KLabel from "@/components/KLabel";
import KDialog from "@/components/KDialog";
import KInputDate from "@/components/KInput/Date";
import SectionHeader from "@/components/SectionHeader";

import moment from "moment";
import feesCalculate from "./feesCalculate";
import { currencyBR } from "klawtil";

export default {
  components: {
    KForm,
    KAlert,
    KLabel,
    KDialog,
    KInputDate,
    SectionHeader,
  },

  data() {
    return {
      visible: false,
      loading: false,
      form: {},
      data: {},
      errors: {},
      inputSettings: {
        dense: true,
        outlined: true,
      },
      dialogActions: [
        {
          label: "Gerar 2ª Via",
          eventName: "click:save",
        },
      ],
    };
  },

  computed: {
    /**
     * Informações que serão enviadas para o backend
     */
    payload() {
      const payload = {
        apply_fees: this.form.apply_fees,
        expires_at: this.form.expires_at,
        connection_id: this.form.connection && this.form.connection.id,
      };

      if (payload.apply_fees) {
        payload.fees_starts_at = this.form.fees_starts_at;
        payload.fees_ends_at = this.form.fees_ends_at;
      }

      return { id: this.data.id, payload };
    },

    /**
     * Boleto já está vencido?
     */
    isExpired() {
      return moment(this.data.expires_at).isBefore(moment().add(1, "day"));
    },

    /**
     * Mostra dados auxiliares para o cálculo
     */
    auxliaryData() {
      try {
        const connection = this.form && this.form.connection;

        const fees = feesCalculate(this.payload.payload, this.data, connection);

        return fees;
      } catch (error) {
        return {};
      }
    },

    connectionSettings() {
      try {
        const connection = this.form && this.form.connection;

        return connection.settings;
      } catch (error) {
        return {};
      }
    },

    fine() {
      const fine = this.connectionSettings.fine;

      if (fine) {
        if (fine.type === "value") return currencyBR(fine.value);
        else if (fine.type === "percent") return fine.value + "%";
      }
    },

    interest() {
      const interest = this.connectionSettings.interest;

      if (interest) {
        if (interest.type === "value") return currencyBR(interest.value);
        else if (interest.type === "percent") return interest.value + "%";
      }
    },

    connections() {
      return this.$store.getters["financial_connections/allActive"];
    },
  },

  methods: {
    async save() {
      try {
        // console.log("Salvou", this.form);
        // alert("salvou");
        // const payload = { id: this.data.id, payload: this.payload };
        // console.log("Payload", payload);

        // return;
        this.errors = {};
        this.loading = true;
        await this.$store.dispatch("financials/duplicate", this.payload);
        this.loading = false;
        this.$emit("save");
        this.$message.success("2ª Via gerada com sucesso");
        this.close();
      } catch (error) {
        console.log(error);
        this.loading = false;
        this.errors = this.$message.serverError(error);
      }
    },
    open(data) {
      // this.data = { ...data };
      this.data = this.$clone(data);

      this.form = {
        apply_fees: false,

        // Nova data de vencimento
        // Dia seguinte
        expires_at: moment()
          .add(1, "day")
          .format("YYYY-MM-DD"),

        // Data de início da cobrança de juros
        // Dia seguinte ao vencimento
        fees_starts_at: moment(data.expires_at)
          .add(1, "day")
          .format("YYYY-MM-DD"),

        // Data final de cobrança de juros
        //
        fees_ends_at: moment().format("YYYY-MM-DD"),
      };

      this.visible = true;
    },

    clear() {
      this.form = {};
      this.data = {};
      this.errors = {};
    },

    close() {
      this.clear();
      this.visible = false;
    },
  },
};
</script>

<style>
</style>
