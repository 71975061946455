<template>
  <v-row class="ma-0">
    <v-col cols="12" class="pb-0">
      <SectionHeader :title="name" />
    </v-col>

    <v-col cols="12" class="pt-0">
      <v-card outlined rounded class="mb-3">
        <v-list class="bordered py-0">
          <v-list-item
            v-for="charge in charges"
            :key="charge.id"
            @click="$emit('click:item', charge)"
          >
            <!--  -->
            <v-list-item-content>
              <v-list-item-title>
                {{ charge.description }}
                <span class="grey--text"> #{{ charge.id }} </span>
              </v-list-item-title>
              <v-list-item-subtitle>
                <KChip red
                  >Vencimento em {{ charge.expires_at | dateBR }}</KChip
                >
                <KChip v-if="charge.billet_text_line" cyan>Cobrança</KChip>
              </v-list-item-subtitle>
            </v-list-item-content>

            <!--  -->
            <v-list-item-action class="green--text">
              {{ charge.amount | currency }}
            </v-list-item-action>
          </v-list-item>

          <!-- Total -->
          <v-list-item>
            <!--  -->
            <v-list-item-content class="font-weight-bold">
              Total
            </v-list-item-content>

            <!--  -->
            <v-list-item-action class="font-weight-bold">
              {{ sum | currency }}
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import KChip from "@/components/KChip";
import SectionHeader from "@/components/SectionHeader";

export default {
  components: {
    KChip,
    SectionHeader
  },

  props: {
    id: Number,
    name: String,
    charges: Array
  },

  computed: {
    sum() {
      return this.charges.reduce((prev, curr) => prev + +curr.amount, 0);
    }
  }
};
</script>

<style>
</style>
